<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Submitted Forms</Text>
      <!-- <Btn
        el="shadow-sm"
        @click="to('/dashboard/recordings/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      > -->
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <div
          :class="{ active: forms.section == 'applications' }"
          @click="setSection('applications')"
        >
          Applications
        </div>
        <div
          :class="{ active: forms.section == 'info sessions' }"
          @click="setSection('info sessions')"
        >
          Info Sessions
        </div>
        <div
          :class="{ active: forms.section == 'contact' }"
          @click="setSection('contact')"
        >
          Contact Me
        </div>

        <!-- <div
          :class="{ active: forms.section == 'draft' }"
          @click="setSection('draft')"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div> -->
      </div>
      <div el="input-list">
        <List el="column xsm">
          <div class="dropdown" v-if="$store.getters.isAdmin">
            <!-- <img
            src="../../assets/img/ai_profile.jpg"
            alt="profile"
            class="profileImg"
            loading="lazy"
          /> -->
            <span
              class="material-icons"
              @click="forms.filters.showDropdown = !forms.filters.showDropdown"
              >filter_list</span
            >

            <div class="menu" v-if="forms.filters.showDropdown">
              <div>
                Filters coming soon...
                <!-- <Text>Tags</Text>
                <div class="tags">
                  <Text
                    v-for="item in forms.filters.tagList"
                    :key="item"
                    @click="toggleTag(item)"
                    :class="{ active: forms.filters.tags.includes(item) }"
                    >{{ item }}</Text
                  >
                </div> -->
              </div>
            </div>
          </div>
          <label el="input">
            <input
              type="text"
              placeholder="Search..."
              v-model="forms.searchInput"
              el
            />
          </label>
        </List>
      </div>
    </List>

    <table el="table" v-if="forms.section == 'applications'">
      <thead>
        <tr>
          <th>Program</th>
          <th>Program Date</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody v-if="filteredForms != null">
        <tr
          v-for="item in filteredForms"
          :key="item"
          @click="to('/dashboard/forms/applications/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <!-- <td>{{ formatDate(item.submittedOn) }}</td> -->
          <td>{{ item.program.name }}</td>
          <td>{{ item.program.date }}</td>
          <td>{{ item.firstname }} {{ item.lastname }}</td>
        </tr>
      </tbody>
    </table>
    <table el="table" v-if="forms.section == 'info sessions'">
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody v-if="filteredForms != null">
        <tr
          v-for="item in filteredForms"
          :key="item"
          @click="to('/dashboard/forms/infosession/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <!-- <td>{{ formatDate(item.submittedOn) }}</td> -->
          <td>{{ formatDate(item.submittedOn) }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
        </tr>
      </tbody>
    </table>
    <table el="table" v-if="forms.section == 'contact'">
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody v-if="filteredForms != null">
        <tr
          v-for="item in filteredForms"
          :key="item"
          @click="to('/dashboard/forms/contact/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <!-- <td>{{ formatDate(item.submittedOn) }}</td> -->
          <td>{{ formatDate(item.submittedOn) }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
        </tr>
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      forms: {
        searchInput: "",
        section: "applications",
        filters: {
          showDropdown: false,
          tag: "programming",
          // tagsContain:['SET 3'],
          tags: ["SET 4"],
          tagList: ["SET 3", "SET 4"],
          status: "publish",
        },
      },
    };
  },
  watch: {
    "forms.section": function (val) {
      this.forms.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
  },
  methods: {
    toggleTag(item) {
      let tags = this.forms.filters.tags;
      if (tags.includes(item)) {
        let i = tags.indexOf(item);
        if (i > -1) {
          tags.splice(i, 1);
        }
      } else {
        tags.push(item);
      }
    },
    formatDate(date) {
      // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(date);
      return d.toLocaleString();
    },
    setSection(name) {
      this.forms.section = name;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredForms() {
      var input = this.forms.searchInput;
      let x = this;
      // let checker = (arr, target) => target.every((v) => arr.includes(v));
      let list = [];
      switch (x.forms.section) {
        case "applications":
          list = filter(
            this.$store.state.forms.applicationList,
            function (item) {
              return (
                lowerCase(
                  `${item.firstname} ${item.lastname} ${item.email} ${item.program.date}`
                ).search(lowerCase(input)) >= 0
              );
            }
          );
          break;
        case "info sessions":
          list = filter(
            this.$store.state.forms.infoSessionList,
            function (item) {
              return (
                lowerCase(
                  `${item.name} ${item.email} ${item.referralCode}`
                ).search(lowerCase(input)) >= 0
              );
            }
          );
          break;
        case "contact":
          list = filter(this.$store.state.forms.contactList, function (item) {
            return (
              lowerCase(`${item.name} ${item.email}`).search(
                lowerCase(input)
              ) >= 0
            );
          });
          break;
      }

      return list;
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("forms/loadApplicationList");
    this.$store.commit("forms/loadInfoSessionList");
    this.$store.commit("forms/loadContactList");
  },
};
</script>
<style lang="scss" scoped>

.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  min-height: 100px;
  width: 250px;
  box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.05);
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown .menu > *:not(.none) {
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  color: var(--color-65);
}
.dropdown .menu > * .material-icons {
  font-size: 24px;
}
.dropdown .menu > *:not(.none):hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-85);
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
</style>
